<template>
    <div class="x-page-container" ref="resize">

        <div class="x-page-btn">
            <el-button type="primary" round @click="handleAdd">预警设置</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" />
            <el-table-column width="180" prop="shangPinMC" label="商品名称" />
            <el-table-column width="180" prop="shangPinGuiGe" label="商品规格" />
            <el-table-column width="180" prop="shangPinHuoHao" label="商品货号" />
            <el-table-column width="180" prop="shangPinTiaoMa" label="商品条码" />
            <el-table-column width="180" prop="shangPinDW" label="商品单位" />
            <el-table-column  prop="shengChanRQ" label="生产日期" show-tooltip-when-overflow/>
            <el-table-column  prop="baoZhiQiTian" label="保质期（天）" />
            <el-table-column width="180" prop="chengBenJia" label="入库价（元）" />
            <el-table-column width="180" prop="shiJiKuCun" label="剩余数量" />
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
    </div>
</template>
<script>
    import * as service from "@/service/kcgl/KuCunZhuangKuang";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/spgl/spxx/ShangPinYuJingEdit";
    export default {
        name: "ShangPinLinQiList",
        mixins: [XTableBase],
        components: {Edit},
        data() {
            this.refreshService = service.linQilist;
            this.service = service;
            return {
                search: {
                },
            }
        },
        methods:{
            refresh() {
                this.linQilist;
                //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
        }
    }
</script>

<style scoped>
</style>
